'use strict';

Gri.module({
  name: 'card-gamma',
  ieVersion: null,
  $el: $('.card-gamma'),
  container: '.card-gamma',
  fn: function () {
    
  }
});
